import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import classnames from 'classnames'
import Equalizer from '../components/Equalizer'
import Layout from '../components/layout'
import ArticlesColumn from '../components/ArticlesColumn'
import InterviewItem from '../components/InterviewItem'
import PageHeader from '../components/PageHeader'

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_ID,
  process.env.GATSBY_ALGOLIA_PUBLIC_KEY
)

export default class SearchPage extends React.PureComponent {
  state = {
    articleHits: [],
    podcastHits: [],
  }

  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  async componentDidMount() {
    const searchParams = new UrlSearchParamsPolyfill(this.props.location.search)

    const response = await client.search([
      { indexName: 'articles', query: searchParams.get('q') },
      { indexName: 'podcasts', query: searchParams.get('q') },
    ])

    this.setState({
      articleHits: response.results[0].hits,
      podcastHits: response.results[1].hits,
    })
  }

  async componentDidUpdate(props) {
    const oldSearchParams = new UrlSearchParamsPolyfill(props.location.search)
    const searchParams = new UrlSearchParamsPolyfill(this.props.location.search)

    if (oldSearchParams.get('q') !== searchParams.get('q')) {
      const response = await client.search([
        { indexName: 'articles', query: searchParams.get('q') },
        { indexName: 'podcasts', query: searchParams.get('q') },
      ])

      this.setState({
        articleHits: response.results[0].hits,
        podcastHits: response.results[1].hits,
      })
    }
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
      .filter(node => node)
  }

  render() {
    const searchParams = new UrlSearchParamsPolyfill(this.props.location.search)

    const articles = this.state.articleHits.map(hit => ({
      uid: hit.uid,
      createdAt: hit.createdAt,
      category: {
        _meta: {
          uid: hit.focusAreaUid,
        },
      },
      description: hit.excerpt,
      ...hit,
    }))

    const podcasts = this.state.podcastHits.map(podcast => ({
      title: podcast.title,
      focusarea: {
        _meta: {
          uid: podcast.focusAreaUid,
        },
      },
      expert: {
        name: podcast.expertName,
        title: podcast.expertTitle,
        photo: {
          url: podcast.expertPhoto,
        },
      },
      _meta: {
        uid: podcast.uid,
        firstPublicationDate: podcast.createdAt,
      },
    }))

    return (
      <Layout searchQuery={searchParams.get('q')}>
        <PageHeader titles={['Search results']} />
        <div className="container lg:max-w-xl mx-auto __mt-32">
          <div
            className={classnames('mt-16', {
              'mb-32': false,
            })}
          >
            <ArticlesColumn articles={articles} />
          </div>
        </div>
        <div className="container lg:max-w-xl mx-auto mb-16">
          <Equalizer
            byRow={true}
            forceGroupItemsCount
            groupItemsCount={2}
            enabled={() => this.media.matches}
            nodes={() => this.getNodesForEqualizer('nameNode')}
          >
            <Equalizer
              byRow={true}
              forceGroupItemsCount
              groupItemsCount={2}
              enabled={() => this.media.matches}
              nodes={() => this.getNodesForEqualizer('authorTitleNode')}
            >
              <Equalizer
                byRow={true}
                forceGroupItemsCount
                groupItemsCount={2}
                enabled={() => this.media.matches}
                nodes={() => this.getNodesForEqualizer('titleNode')}
              >
                <Equalizer
                  byRow={true}
                  forceGroupItemsCount
                  groupItemsCount={2}
                  enabled={() => this.media.matches}
                  nodes={() => this.getNodesForEqualizer('headerNode')}
                >
                  <div className="lg:flex lg:flex-wrap lg:-mx-4">
                    {podcasts.map((podcast, index) => (
                      <div className="lg:w-1/2 lg:px-4" key={index}>
                        <InterviewItem
                          nameRef={node => (this[`nameNode${index}`] = node)}
                          titleRef={node => (this[`titleNode${index}`] = node)}
                          headerRef={node =>
                            (this[`headerNode${index}`] = node)
                          }
                          authorTitleRef={node =>
                            (this[`authorTitleNode${index}`] = node)
                          }
                          avatar={podcast.expert.photo.url}
                          name={podcast.expert.name}
                          authorTitle={podcast.expert.title}
                          title={podcast.title}
                          focusAreaUid={podcast.focusarea._meta.uid}
                          expertTitle={podcast.expert.title}
                          createdAt={podcast._meta.firstPublicationDate}
                          index={index}
                          uid={podcast._meta.uid}
                        />
                      </div>
                    ))}
                  </div>
                </Equalizer>
              </Equalizer>
            </Equalizer>
          </Equalizer>
        </div>
      </Layout>
    )
  }
}

class UrlSearchParamsPolyfill {
  constructor(query) {
    this.query = query
  }
  getSearchObject = () => {
    const { query } = this
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
            let [key, value] = param.split('=')
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, ' '))
              : ''
            return params
          }, {})
      : {}
  }
  getAll = () => {
    const searchParams = this.getSearchObject()
    return searchParams
  }
  get = param => {
    const searchParams = this.getSearchObject()
    return searchParams[param]
  }
  setUrl = (param, value) => {
    const searchParams = this.getSearchObject()
    searchParams[param] = value
    return Object.keys(searchParams)
      .map(key => key + '=' + searchParams[key])
      .join('&')
  }
}
